import React from 'react';
import './Education.css';
import GMULogo from '../GMULogo.png'; // Ensure this path is correct
import PlanoWestLogo from '../PlanoWestLogo.png'; // Ensure this path is correct

const Education = () => {
  return (
    <section id="education">
      <h2>Education</h2>
      <div className="education-wrapper">
        <div className="education-container">
          <div className="education-logo">
            <img src={GMULogo} alt="Plano West Senior High School Logo" />
          </div>
          <div className="education-info">
            <div className="info-content">
              <h3>George Mason University</h3>
              <p>(2022-2026), Double major in Computer Science and Physics</p>
            </div>
          </div>
        </div>
        <div className="education-line"></div>
        <div className="education-container">
          <div className="education-logo">
            <img src={PlanoWestLogo} alt="George Mason University Logo" />
          </div>
          <div className="education-info">
            <div className="info-content">
              <h3>Plano West Senior High School</h3>
              <p>(2020-2022), High School Diploma</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Education;
