import React from 'react';
import './About.css';
import ProfilePicture from '../Yahia.jpg'; // Ensure this path is correct
import Resume from '../Resume.pdf'

const About = () => {
  return (
    <section id="about">
      <h2>About Me</h2>
      <div className="about-content">
        <div className="about-image">
          <img src={ProfilePicture} alt="Yahia Salman" />
          <div className="about-info">
            <h3>Yahia Salman</h3>
            <p> I'm a Computer Science and Physics student at George Mason University with a passion for deep learning, quantitative trading, and quantum computing. I am driven by curiosity and the desire to innovate in the fields of artificial intelligence and advanced computing technologies. 
            <br /><br />
            In my free time, I enjoy cooking, playing chess, and solving old competition physics problems. These activities not only help me relax but also sharpen my problem-solving skills and strategic thinking.</p>
            <p>Click here to view my <a href={Resume} target="_blank" rel="noopener noreferrer">Resume!</a></p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
