import React from 'react';
import './Research.css';

const researchProjects = [
  {
    id: 1,
    title: "Enhancing Navigation for the Visually Impaired with AI-Powered Visual Aid Devices",
    description: "This project addresses a critical gap in assistive technologies for visually impaired individuals by developing an innovative AI-powered visual aid device..."
  },
  {
    id: 2,
    title: "Advancing Neural Network Efficiency with Cutting-Edge Quantization Techniques",
    description: "In this research, we explore innovative quantization methods aimed at optimizing neural network performance and efficiency..."
  },
  {
    id: 3,
    title: "Quantum Enhancements in Gradient Descent Algorithms for Efficient Computation",
    description: "This research delves into the quantum computing realm, focusing on optimizing the Quantum Gradient Descent Algorithm to enhance computational processes at the quantum level..."
  },
  {
    id: 4,
    title: "AI Robot Dog Companion",
    description: "This project aims to create a robotic dog companion that recognizes human emotions with up to 90% accuracy, utilizing state-of-the-art AI techniques..."
  },
];

const Research = () => {
  return (
    <section id="research">
      <h2>Research</h2>
      <div className="research-cards">
        {researchProjects.map((project) => (
          <div className="research-card" key={project.id}>
            <h3>{project.title}</h3>
            <p>{project.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Research;
