import React from 'react';
import { motion } from 'framer-motion';
import './App.css';
import Navbar from './components/Navbar';
import About from './components/About';
import Research from './components/Research';
import Projects from './components/Projects';
import Education from './components/Education';
import Awards from './components/Awards';

function App() {
  return (
    <div className="App">
      <Navbar />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <About />
        <Research />
        <Projects />
        <Education />
        <Awards />
      </motion.div>
    </div>
  );
}

export default App;
