import React from 'react';
import { motion } from 'framer-motion';
import './Projects.css';

const projects = [
  {
    title: "Rybum",
    subtitle: "iOS Album Sharing App",
    image: "/rybum.png",
    description: "Developed an iOS app for creating and sharing personalized music albums. Implemented with Swift UIKit, NodeJS/Typescript backend, and MySQL, featuring a RESTful API for enhanced user interaction."
  },
  {
    title: "Teach.ai",
    subtitle: "Real-Time Classroom Emotion and Attention Analysis",
    image: "/teachai.png",
    description: "Designed a model to detect student emotions and attention using VGG19 and FER-13, achieving 75% accuracy. Integrated real-time feedback via an iOS interface to assist educators.",
    videoLink: "https://www.youtube.com/watch?v=ufALuEPzDLI&t=26s&ab_channel=YahiaSalman",
  },
  {
    title: "Prepr",
    subtitle: "AI Mock Interview Platform",
    image: "/PreprLogo.jpg",
    description: "Created an interview preparation platform using AI to provide feedback on eye contact and speech. Employed OpenCV for iris tracking and speech AI models for realistic interactions.",
    videoLink: "https://www.youtube.com/watch?v=GSlQammGaXs&t=89s&ab_channel=YahiaSalman",
  },
  {
    title: "REPS",
    subtitle: "Fitness Tracking and Analysis Application",
    image: "/Reps.png",
    description: "Developed a fitness app that records workouts in real-time using AI Pose Detection. Features exercise recognition with OpenCV and a macronutrient tracker for comprehensive fitness management."
  },
  {
    title: "NeuroNavigate",
    subtitle: "Supportive Educational Tool for Children with Autism",
    image: "/NeuroNavigate.png",
    description: "Built an educational app for children with autism to practice social skills, powered by Python, Flask, OpenCV, and AI models. Provides interactive exercises for emotional recognition and conversation skills.",
    videoLink: "https://www.youtube.com/watch?v=ZqIpjJDmcwo&t=8s&ab_channel=YahiaSalman",
  }
];

const Projects = () => {
  return (
    <section id="projects">
      <h2>Projects</h2>
      <div className="project-cards">
        {projects.map((project) => (
          <motion.div 
            className="project-card" 
            key={project.title}
            whileHover={{ scale: 1.05 }}
          >
            {/* <img src={project.image} alt={project.title} /> */}
            <h3>{project.title}</h3>
            <h4>{project.subtitle}</h4>
            <p>{project.description}</p>
            {project.videoLink && (
              <a href={project.videoLink} target="_blank" rel="noopener noreferrer">Watch Video</a>
            )}
          </motion.div>
        ))}
      </div>
    </section>
  );
}

export default Projects;
