import React from 'react';
import { FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-scroll';
import './Navbar.css';

const Navbar = () => {
  const offsetValue = -60; // Adjust this value according to the height of your navbar

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <h1>Yahia Salman</h1>
      </div>
      <div className="navbar-right">
        <Link to="about" smooth={true} duration={500} offset={offsetValue}>About</Link>
        <Link to="research" smooth={true} duration={500} offset={offsetValue}>Research</Link>
        <Link to="projects" smooth={true} duration={500} offset={offsetValue}>Projects</Link>
        <Link to="education" smooth={true} duration={500} offset={offsetValue}>Education</Link>
        <Link to="awards" smooth={true} duration={500} offset={offsetValue}>Awards</Link>
        <a href="https://www.linkedin.com/in/yasalman" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
        <a href="https://github.com/AwesomeCuber6543" target="_blank" rel="noopener noreferrer">
          <FaGithub />
        </a>
        <a href="mailto:salmanyahia04@gmail.com">
          <FaEnvelope />
        </a>
      </div>
    </nav>
  );
}

export default Navbar;
